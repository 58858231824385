import AppRouter from './router/index.jsx';
import "./style.scss"

function App() {
  return (
    <AppRouter/>
  );
}

export default App;
